import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import Layout from "../components/Layout"
import { H3 } from "../elements/shared/texts"
import { BsCheckCircle } from "react-icons/bs";
import WithLocation from "../components/WithLocation"
import { navigate } from "gatsby"
import { IsBrowser } from "../helpers/utils"
import { PrimaryLink } from "../elements/shared/buttons"
import { motion} from "framer-motion"

const Container = styled.div`
  ${tw`flex flex-col items-center my-32 h-full lg:w-5/12 md:w-5/12 py-24  sm:px-0 px-4 mx-auto`}
`

const Confirmation = ({ search }) => {
  const { email } = search
  if (!email) {
    if (IsBrowser) {
      navigate("/")
    }
    return null
  }
  return (
    <Layout>
      <div className={"container mx-auto"}>
        <Container>
          <BsCheckCircle size={100}  style={{color: 'green'}} />
          <H3>Email address verified.</H3>
          <p className={"pt-4"}><span className={"font-extrabold"}> {email} </span> successfully verified!</p>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <PrimaryLink to={"/auth"} className={"mt-4"}>
              Get started
            </PrimaryLink>
          </motion.div>
        </Container>
      </div>
    </Layout>
  )
}

export default WithLocation(Confirmation)